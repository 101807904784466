import CarouselItemCard from "../CarouselItemCard";

import { useNavigate } from "react-router-dom";
import { ASSETS_URL } from "../../api/config";

const { default: Carousel, CarouselItem } = require("../Carousel");

function ArticleDetailsFooter({ items, otherTheme, onClick }) {
    const navigate = useNavigate();

    const arrowUp = require("../../assets/images/Vector_8.png");

    return (
        <div style={{ marginBottom: "5vh" }}>
            {items.length > 0 && (
                <>
                    <div className="same-categorie-div">
                        <button onClick={onClick}>
                            <img src={arrowUp} alt="flèche du haut" />
                        </button>
                        <h1>Du même genre</h1>
                    </div>

                    <Carousel>
                        {items.map((item, id) => {
                            const onCardClick = () => {
                                navigate(`/articleDetails/${item.id}`, { replace: true });

                                window.location.reload(false);
                            };

                            return (
                                <CarouselItem key={id}>
                                    <CarouselItemCard
                                        onCardClick={onCardClick}
                                        image={`${ASSETS_URL}${
                                            item?.image_footer?.url ?? item?.image?.url
                                        }`}
                                        title={item.title}
                                    />
                                </CarouselItem>
                            );
                        })}
                    </Carousel>

                    <div className="footer_image_container">
                        <img
                            src={require("../../assets/images/illustration-menu-bas-de-page_1.png")}
                            alt=""
                        />
                        <img
                            className="footer_line"
                            src={require("../../assets/images/Line_5.png")}
                            alt=""
                        />
                        <img
                            src={require("../../assets/images/illustration-menu-bas-de-page_2.png")}
                            alt=""
                        />
                    </div>
                </>
            )}

            {otherTheme.length > 0 && (
                <>
                    <div className="same-categorie-div">
                        <h1>Autres Themes</h1>
                    </div>
                    <Carousel>
                        {otherTheme.map((item, id) => {
                            const onCardClick = () => {
                                navigate(`/ArticlesList/${item.id}`, { replace: true });

                                window.location.reload(false);
                            };

                            return (
                                <CarouselItem key={id}>
                                    <CarouselItemCard
                                        onCardClick={onCardClick}
                                        image={`${ASSETS_URL}${
                                            item?.image_footer?.url ?? item?.image?.url
                                        }`}
                                        title={item.title}
                                    />
                                </CarouselItem>
                            );
                        })}
                    </Carousel>
                </>
            )}
        </div>
    );
}

export default ArticleDetailsFooter;
