import Masonry from "react-masonry-css";
import CardItem from "../../components/CardItem";
import "../../App.css";
import { useEffect, useState } from "react";
import { getArticles } from "../../api/articles";
import { useNavigate } from "react-router-dom";
import { ASSETS_URL } from "../../api/config";
import styled from "styled-components";

function Home() {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  async function getAllArticles() {
    await getArticles().then((response) => {
      setArticles(response.data);
    });
  }

  useEffect(() => {
    getAllArticles();
  }, []);

  return (
    <Container className="container">
      <Masonry
        breakpointCols={2}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {articles.map((article, id) => {
          return (
            <CardItem
              key={id}
              onClick={() => navigate(`/articleDetails/${article.id}`)}
              image={`${ASSETS_URL}${article.image.url}`}
              title={article.title}
            />
          );
        })}
      </Masonry>
    </Container>
  );
}

const Container = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
`;

export default Home;
