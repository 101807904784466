import styled from "styled-components";
import "./index.css";

function MenuCard({ image, title, onClick }) {
  return (
    <MainButton onClick={onClick}>
      <div className="card_item">
        {image && <MenuImage src={image} alt="image_menu" />}
        {!image && title && <h2>{title}</h2>}
      </div>
    </MainButton>
  );
}

const MainButton = styled.button`
  border: none;
  background-color: #e3d9fb;
  width: 100vw;
  height: 22vh;
  margin-bottom: 7px;
`;

const MenuImage = styled.img`
  width: 100vw;
  height: 22vh;
  object-fit: cover;
  object-position: 20% 20%;
  display: block;
  background-size: cover;
`;

export default MenuCard;
