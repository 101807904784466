import "./index.css";
import Parser from "html-react-parser";

function CarouselItemCard({ image, onCardClick, title }) {
  return (
    <div className="card-container">
      <button className="item-card-button" onClick={onCardClick}>
        <img className="carousel-image" src={image} alt="test" />
        <p>{Parser(title)}</p>
      </button>
    </div>
  );
}

export default CarouselItemCard;
