import axios from "axios";
import { BASE_URL } from "../config";

async function getArticles() {
  return await axios.get(BASE_URL + "articles.json", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "X-Requested-With",
      "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json",
    },
  });
}

async function getArticle(id) {
  return await axios.get(BASE_URL + "articles/" + id + ".json");
}

export { getArticles, getArticle };
