import React, { useEffect } from "react";
import "./App.css";

import styled from "styled-components";

import NavBar from "./components/NavBar";
import { Routes, Route } from "react-router-dom";

import ArticlesList from "./views/ArticlesList";
import Home from "./views/Home";
import ArticleDetails from "./views/ArticleDetails";
import { useState } from "react";

function App() {
  const [showLoader, setShowLoader] = useState(true);

  const loaderImage = require("./assets/images/logo.gif");

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 0);
  }, []);

  return (
    <div>
      {showLoader ? (
        <div className="loading_image_container">
          <img
            className="loading_image"
            src={loaderImage}
            alt="chargement..."
          />
        </div>
      ) : (
        <>
          <NavBar />
          <Main className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/articleDetails/:id" element={<ArticleDetails />} />
              <Route path="/ArticlesList/:id" element={<ArticlesList />} />
            </Routes>
          </Main>
        </>
      )}
    </div>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: row;
`;

export default App;
