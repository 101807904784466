import useIsMounted from "ismounted";
import React, { useState, useEffect } from "react";

import "./index.css";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const arrowLeft = require("../../assets/images/Vector_10.png");
  const arrowRight = require("../../assets/images/Vector_9.png");
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsCount, setItemsCount] = useState();
  const isMounted = useIsMounted();

  useEffect(() => {
    setItemsCount(React.Children.count(children));
  }, [children, isMounted]);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = React.Children.count(children) - 1;
    }
    setActiveIndex(newIndex);
  };

  return (
    <div className="main-container">
      <button
        className="prev_button"
        onClick={() => {
          updateIndex(activeIndex - 1);
        }}
      >
        <img src={arrowLeft} alt="flèche de gauche" />
      </button>

      <button
        className="next_button"
        onClick={() => {
          updateIndex(activeIndex + 1);
        }}
      >
        <img src={arrowRight} alt="flèche de droite" />
      </button>
      <div className="carousel">
        <div
          className="inner"
          style={
            itemsCount < 3
              ? {
                  display: "flex",
                  justifyContent: "center",
                  transform: `translateX(-${activeIndex * 0}%)`,
                }
              : { transform: `translateX(-${activeIndex * 33}%)` }
          }
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: "33%" });
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
