import React, { useCallback, useEffect, useState } from "react";
import "./NavBar.css";
import FlatList from "flatlist-react";
import { getCategories } from "../../api/categories";
import { ASSETS_URL } from "../../api/config";
import { useLocation, useNavigate } from "react-router-dom";
import { getArticle } from "../../api/articles";

function NavBar() {
  const [showNav, setShowNav] = useState(false);
  const [categories, setCategories] = useState({});
  const [, setCurrentCategories] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.split("/");

  const arrowBack = require("../../assets/icons/backArrow.png");

  const menuIcon = require("../../assets/icons/menu-icon.png");
  const cross = require("../../assets/icons/cross.png");

  async function getCategorieList() {
    await getCategories().then((response) => {
      setCategories(response.data);
    });
  }

  const getCurrentCategorie = useCallback(async () => {
    await getArticle(currentLocation[2]).then((response) => {
      setCurrentCategories(response.data.category_id);
    });
  }, [currentLocation]);

    useEffect(() => {
    if (currentLocation[1] === "articleDetails") {
      getCurrentCategorie();
    }
    getCategorieList();
    // eslint-disable-next-line
  }, []);
  //});

  const renderCategories = (categorie, id) => {
      let link ;
      if (categorie.id === 10)
        link = `https://magazine.lisaconnect.fr`;
      else
        link = `/ArticlesList/${categorie.id}`;
    return (
      <div key={id} className="link_container">
        <a href={link} className="navbar_link">
          <div className="image-container">
            <img
              className="img_link_container"
              src={`${ASSETS_URL}${categorie.image.url}`}
              alt="categorie"
            />
          </div>
          <div className="navbar-title-container">
            <p className="navbar-title">{categorie.title}</p>
          </div>
        </a>
      </div>
    );
  };

  const handleShowNav = () => setShowNav(!showNav);
  const onBackClick = () => navigate(-1);

  return (
    <nav className={`navbar ${showNav ? "show-nav" : "hide-nav"}`}>
      <button
        className={` ${
          currentLocation[1] === ""
            ? "back-arrow-hide"
            : showNav
            ? "back-arrow-hide"
            : "back-arrow"
        }`}
        onClick={onBackClick}
      >
        <img src={arrowBack} alt="retour en arrière" />
      </button>

      <a
        className={`home-icon-link ${showNav ? "home-icon-fixed" : ""}`}
        href="/"
      >
        <img
          className="nav-logo"
          src={require("../../assets/images/Logo-LisaConnect-profil.png")}
          alt="logo"
        />
      </a>
      <ul className="navbar_links">
        <li className="navbar_item">
          <FlatList
            list={categories}
            renderItem={renderCategories}
            renderWhenEmpty={() => <div>List is empty!</div>}
          />
        </li>
      </ul>

      <button
        className={` ${showNav ? "navbar_burger_hide" : "navbar_burger"}`}
        onClick={handleShowNav}
      >
        {showNav ? (
          <img className="cross-menu" src={cross} alt="fermer le menu" />
        ) : (
          <img src={menuIcon} alt="icon du menu" />
        )}
      </button>
    </nav>
  );
}

export default NavBar;
