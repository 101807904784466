import styled from "styled-components";
import Parser from "html-react-parser";

function CardItem({ content, onClick, title, image }) {
  const style = {
    // height: bool ? "150px" : "270px",
  };
  return (
    <MainButton className="home-card-container" onClick={onClick}>
      <MainContainer>
        {image && (
          <CardBackgroundImage
            className="home-image"
            src={image}
          ></CardBackgroundImage>
        )}
        {content && (
          <Card style={style} className="card">
            {content}
          </Card>
        )}
        <CardTitleContainer className="card-title">
          <Title>{Parser(`${title}`)}</Title>
        </CardTitleContainer>
      </MainContainer>
    </MainButton>
  );
}

const MainButton = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: none;
  margin-bottom: 30px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardBackgroundImage = styled.img`
  width: 40vw;
  border-radius: 17px;
`;

const Card = styled.div`
  background-color: #ffd5cd;
  border-radius: 17px;
  margin-bottom: 10px;
  height: 268px;
  width: 100%;
`;
const CardTitleContainer = styled.div``;

const Title = styled.p`
  font-family: Quattrocento;
  font-size: 12px;
  text-transform: uppercase;
  text-align: start;
  margin-top: 10px;
`;

export default CardItem;
