import "../../App.css";

import React, { useEffect, useRef, useState, useCallback } from "react";
import MenuCard from "../../components/MenuCard";
import "./index.css";
import ArticleDetailsFooter from "../../components/ArticleDetailsFooter";
import { useParams } from "react-router-dom";
import { getArticle, getArticles } from "../../api/articles";
import Parser from "html-react-parser";
import { ASSETS_URL } from "../../api/config";

function ArticleDetails() {
  const { id } = useParams();

  const refArticle = useRef(null);
  const refTop = useRef(null);

  const [article, setArticle] = useState({ image: { url: "" } });
  const [articles, setArticles] = useState([]);
  const [categoryOther, setCategoryOther] = useState([]);

  const scrollToTop = () =>
    refTop.current.scrollIntoView({ behavior: "smooth" });

  const getArticleDetails = useCallback(async () => {
    await getArticle(id).then((response) => {
      setCategoryOther(response.data.category.category_other);
      setArticle(response.data);
    });
  }, [id]);

  const otherTheme = categoryOther.map((item) => {
    return item.category_other;
  });

  const getArticlesList = useCallback(async () => {
    await getArticles().then((response) => {
      setArticles(response.data);
    });
  }, []);

  const listArticles = articles.filter(
    (item) => item.category_id === parseInt(article.category_id)
  );

  const articlesList = listArticles.filter((item) => item.id !== article.id);

  useEffect(() => {
    getArticleDetails();
    getArticlesList();
  }, [getArticlesList, getArticleDetails]);

  console.log("IMMAGGEE =>", article.large_image);
  return (
    <div ref={refTop} className="main_article">
      {article.category_id === 6 && article.large_image === true ? (
        <>
          <img
            className="horoscope-image"
            src={`${ASSETS_URL}${
              article?.image_principale?.url ?? article?.image?.url
            }`}
            alt="article"
          />
        </>
      ) : article.category_id === 6 && article.large_image === false ? (
        <MenuCard
          image={`${ASSETS_URL}${
            article?.image_principale?.url ?? article?.image?.url
          }`}
        />
      ) : (
        <MenuCard
          image={`${ASSETS_URL}${
            article?.image_principale?.url ?? article?.image?.url
          }`}
        />
      )}

      <h1 ref={refArticle}>{Parser(`${article.title}`)}</h1>

      <div className="content">{Parser(`${article.content}`)}</div>

      <ArticleDetailsFooter
        otherTheme={otherTheme}
        items={articlesList}
        onClick={scrollToTop}
      />
    </div>
  );
}

export default ArticleDetails;
