import "../../App.css";
import MenuCard from "../../components/MenuCard";
import { useNavigate, useParams } from "react-router-dom";
import FlatList from "flatlist-react";
import { useEffect, useState } from "react";
import { getCategorie } from "../../api/categories";
import { getArticles } from "../../api/articles";
import Parser from "html-react-parser";
import { ASSETS_URL } from "../../api/config";

function ArticlesList() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [categorie, setCategorie] = useState([]);

  async function getArticleCategorie() {
    await getCategorie(id).then((response) => {
      setCategorie(response.data);
    });
  }

  async function getArticlesList() {
    await getArticles().then((response) => {
      setArticles(response.data);
    });
  }

  const listArticles = articles.filter(
    (article) => article.category_id === parseInt(id)
  );

  useEffect(() => {
    getArticleCategorie();
    getArticlesList();
    // eslint-disable-next-line
  }, []);

  const renderArticles = (article, id) => {
    const onClick = () => navigate(`/articleDetails/${article.id}`);
    return (
      <MenuCard
        key={id}
        image={`${ASSETS_URL}${
          article?.image_principale?.url ?? article?.image?.url
        }`}
        onClick={onClick}
      />
    );
  };

  return (
    <div className="main_container">
      <h1 className="menu_title">{Parser(`${categorie.title}`)}</h1>
      <FlatList
        list={listArticles}
        renderItem={renderArticles}
        renderWhenEmpty={() => <div>List is empty!</div>}
      />
    </div>
  );
}

export default ArticlesList;
